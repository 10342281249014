/* eslint-disable react/prefer-stateless-function */
import React from "react"

class Submenu extends React.Component {
  render() {
    const { page } = this.props

    return (
      <div className="my-3">
        <div className="container">
          <div className="nav-pillars-items d-flex flex-wrap flex-md-nowrap justify-content-md-between justify-content-center py-3">
            <a
              className="btn btn-outline-success akko-bold"
              href="/raport-de-sustenabilitate-2022"
            >
              {" "}
              2022 ÎN CIFRE{" "}
            </a>
            <a
                className={`text-uppercase btn akko-bold ${
                  page == "campanii"
                    ? "btn-success"
                    : "btn-outline-success"
                }`}
                href="/campanii"
              >
                Campanii
              </a>
            <a
                className={`text-uppercase btn akko-bold ${
                  page == "produse-verzi"
                    ? "btn-success"
                    : "btn-outline-success"
                }`}
                href="/sortiment-sustenabil"
              >
                Sortiment Sustenabil
              </a>
              <a
                  className={`text-uppercase btn akko-bold ${
                      page == "energie-clima-mediu"
                          ? "btn-success"
                          : "btn-outline-success"
                  }`}
                  href="/energie-clima-resurse"
              >
                Energie, climă și resurse
              </a>
              <a
                className={`text-uppercase btn akko-bold ${
                  page == "angajati" ? "btn-success" : "btn-outline-success"
                }`}
                className="text-uppercase btn btn-outline-success akko-bold"
                href="/angajati-societate"
              >
                Angajați și societate
              </a>

          </div>
        </div>
      </div>
    )
  }
}
export default Submenu
